<!-- feature 页 -->
<template>
	<div class='feature'>
		<img src="../../assets/imgs/imageBack.png" style="display:none"></img>
		<top-nav active="3" :isRectangle="true" :istop="true"></top-nav>
		<div class="home">
			<div class="topnav_bg_90"></div>
			<div class="feature_content">
				<div class="title">
					All of the features you need
				</div>
				<div class="sub_title">
					Arcanite offers everything you need for project marketing.
				</div>
				<div class="book_a_demo">
					<div class="book_a_demo_inner">
						<span class="available_for">
							<span>Available for:</span>
							<img src="@/assets/imgs/pc/feature/apple.png" alt="">
							<img src="@/assets/imgs/pc/feature/android.png" alt="">
							<img src="@/assets/imgs/pc/feature/computer.png" alt="">
						</span>
						<span class="book_a_demo_btn"
							@click="calendlyClick">Book A Demo</span>
					</div>
				</div>
				<div class="features_picture">
				</div>
				<div class="part2RecoginizedIcons">
					<div class="part2-topcontent">
						<trusted></trusted>
					</div>
					<div class="part2-bottomcontent">
							<recognised></recognised>
					</div>
				</div>
				<!-- <div class="bottomContent">
					<recognised></recognised>
					<div class="empty">
						<trusted></trusted>
					</div>
				</div> -->
				<br>
				<div class="features_our_features">
					<p class="our_features_title">
						Our Features
					</p>
					<div class="our_features_contents">
						<div class="our_features_contents_item" v-for="(d,i) in feature_contents" :key="i">
							<div class="feature_item_icon">
								<img :src="d.img" alt="">
							</div>
							<p class="feature_item_subtitle">
								{{d.title}}
							</p>
							<p class="feature_item_contents">
								{{d.content}}
							</p>
						</div>
						<div class="our_features_contents_item_more">
							<p class="explorePart">Explore more exclusive features for you</p>
							<div class="button">
								<span class="book_a_demo" @click="calendlyPopClick">
									<span class="book_a_demo_feature_btn">Book A Demo</span>
									<img src="./../../assets/imgs/pc/homepage/p12Vector.png" alt="">
								</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<foot-nav></foot-nav>
	</div>
</template>

<script>
	export default {
		components: {
			"top-nav": resolve => require(["./TopNav.vue"], resolve),
			"foot-nav": resolve => require(["./FooterNav.vue"], resolve),
			"trusted":(resolve) => require(["./Trusted.vue"], resolve),
			"recognised":(resolve) => require(["./Recognised.vue"], resolve),
		},
		data() {
			return {
				isID:false,
				feature_contents: [{
						img: require("@/assets/imgs/pc/feature/feature_item_icon1_1.png"),
						title: "Arcanite App delivers property updates instantly",
						content: "Users can search, favourite, and save properties on their mobile devices with ease. Deliver real-time property updates to your leads instantly with push notifications.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon1_2.png"),
						title: "Get access to exclusive Arcanite products",
						content: "Gain access to virtual reality property rendering technology, exclusive minimal deposit finance products and marketplaces to increase project marketing connections.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon1_3.png"),
						title: "Instant file sharing from mobile devices",
						content: "Share QR codes with links to client contact forms and VR property walk-throughs, via the Arcanite App. Instantly generate marketing brochures displaying property images and specifications, with your own company branding.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon2_1.png"),
						title: "Access all customer information in one app",
						content: "Track customer activity and receive a chronological timeline review of all customer engagements. Add notes, tasks, and appointments to each customer’s profile.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon2_2.png"),
						title: "Prioritise your most valuable leads",
						content: "Focus on leads with the highest follow-through potential and store customer preferences for fast lead generation for upcoming projects.",
					}, {
						img: require("@/assets/imgs/pc/feature/feature_item_icon2_3.png"),
						title: "Automated workflows for time saving benefits",
						content: "Let Arcanite take care of time-consuming, tedious tasks. Arcanite’s automated system is created to handle a wide range of repetitive tasks, minus the element of human error. This will free your most precious resource - your agents’ time and attention, to focus on generating leads and making deals happen.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon3_1.png"),
						title: "Analyse how your business is performing",
						content: "Arcanite’s dashboard generates weekly, monthly, and yearly sales reports to identify gaps in performance and create data files. Data metrics are key in company decision-making processes and Arcanite’s software draws out strategies from this input, to continue to grow your business further.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon3_2.png"),
						title: "Effectively manage your company",
						content: "Set up your Arcanite dashboard to work specifically for your company. Establish team and data management settings that work across the entire platform. Determine what information your team members can view from their front-end Arcanite App.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon3_3.png"),
						title: "The Arcanite team is here to support you",
						content: "Access training material and an extensive list of FAQ’s on the Arcanite App, enabling quick adaptability for all users. Arcanite provides an online support network, digital identity set-up options and additional project update support is available, providing considerable time and money savings.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon4_1.png"),
						title: "Highly compatible with real estate CRM systems",
						content: "Arcanite has the ability to sync with most real estate and generic CRM platforms, as well as other software systems which your company relies on, such as Mailchimp, Zapier, Twilio, Stripe, etc.",
					},
					{
						img: require("@/assets/imgs/pc/feature/feature_item_icon5_1.png"),
						title: "Customise to suit your specific needs",
						content: "Choose from a variety of subscriptions with inclusions to suit your corporation’s operational needs. Customise how you view your projects and integrate additional systems used by your company, creating a comprehensive and centralised space for all workflows.",
					}
				]
			};
		},
		created(){
			if(localStorage.getItem('country')=="id"){
				this.isID=true;
			}else{
				this.isID=false;
			}
		},
		methods: {
			calendlyClick(){
				if(this.isID){
					this.IDshowPopupWidget()
				}else{
					this.showPopupWidget()
				}
			},
			calendlyPopClick(){
				if(this.isID){
					Calendly.showPopupWidget('https://calendly.com/erni-yap');
				}else{
					Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
				}
				return false;

			},
			showPopupWidget() {
				Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
				return false;
			},
			IDshowPopupWidget() {
				Calendly.showPopupWidget('https://calendly.com/erni-yap');
				return false;
			},
		},
	}
</script>
<style lang='scss' scoped>
	// .feature {
	// 	width: 100vw;
	// 	height:100%;
	// 	display: inline-block;
	// 	text-align: center;
	// 	overflow-x:hidden;
	// 	font-family: Poppins;
	// 	min-width: 1210px;
	// 	// margin-right: 20px;
	// }
	.home{
		width: 100vw;
		// width: 1210px;
		display: inline-block;
		text-align: center;
		position: relative;
		font-family: Poppins;
		overflow-x:hidden;
		z-index: 6;

	}

	.feature_content {
		width: 1280px;
		margin-left: auto;
		margin-right: auto;

		.title {
			font-family: Poppins-Bold;
			margin-top: 142px;
			font-size: 56px;
			// font-weight: 700;
			color: #062440;
		}

		.sub_title {
			font-family: Poppins;
			margin-top: 30px;
			font-size: 20px;
			font-weight: 400;
			color: #314455;
		}

		.book_a_demo {
			display: flex;
			justify-content: center;

			.book_a_demo_inner {
				width: 438px;
				height: 62px;
				margin-top: 30px;
				display: inline-block;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.available_for {
					display: flex;
					align-items: center;

					span {
						color: #062440;
						font-size: 20px;
						font-family: Poppins;
					}

					img {
						margin-left: 10px;
						width: 24px;
						height: 24px;
					}

					div {
						margin-left: 30px;
						height: 35px;
						width: 1px;
						background-color: #062440;
					}
				}

				.book_a_demo_btn {
					margin-left: 30px;
					background-color: #1890ff;
					width: 143px;
					height: 42px;
					border-radius: 5px;
					color: #fff;
					font-family: Poppins-Bold;
					font-size: 16px;

					line-height: 42px;
				}

				.book_a_demo_btn:hover {
					cursor: pointer;
					background-color: #096dd9;
				}

				.book_a_demo_btn:active {
					background-color: #0757ae;
				}
			}
		}

		.book_a_demo_feature_btn {
			border-radius: 5px;
			color: #fff;
			font-family: Poppins-Bold !important;
			font-size: 16px;
			line-height: 42px;
		}

		.features_picture {
			margin-top: 90px;
			height: 746px;
			background: url("../../assets/imgs/pc/feature/features_arcanite_background.png") no-repeat center center;
			background-size: 1098px 746px;
		}

		.part2RecoginizedIcons{
			height: 350px;
			margin: 0px auto;
			width: 1210px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			// align-items:center;
			// padding-left: 20px;
			gap: 40px;
		}

		.features_our_features {
			// padding-left: 30px;
			// padding-right: 30px;

			// border: 1px solid green;
			.our_features_title {
				margin-top: 52px;
				font-family: Poppins-Bold;
				font-size: 36px;
				// font-weight: bold;
				text-align: center;
				color: #062440;
			}

			.our_features_contents {
				margin: 52px 30px 100px 30px;
				width: 1185px;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;

				.our_features_contents_item {
					margin-right: 20px;
					margin-bottom: 20px;
					width: 375px;
					height: 356px;
					padding: 20px;
					box-sizing: border-box;
					transition: background-color 0.1s linear;

					&:hover {
						background-color: #eef7ff;
						border-radius: 5px;
					}

					.feature_item_icon {
						text-align: left;
						height: 36px;

						img {
							height: 100%;
						}
					}

					.feature_item_subtitle {
						margin-top: 10px;
						text-align: left;
						font-family: Poppins;
						font-size: 20px;
						font-weight: 700;
						line-height: 1.3;
						color: #062440;
						padding-right: 20px;
					}

					.feature_item_contents {
						margin-top: 10px;
						font-family: Poppins;
						font-size: 16px;
						line-height: 1.63;
						text-align: left;
						color: #314455;
						padding-right: 20px;
						width: 352px;
						/* &:hover {
		    background-color: #eef7ff;
		    border-radius: 5px;
		  } */
					}
				}

				.our_features_contents_item_more {
					margin-left: 20px;
					margin-right: 20px;
					margin-bottom: 20px;
					width: 352px;
					height: 316px;
					/* padding: 40px; */
					box-sizing: border-box;
					background: url("../../assets/imgs/pc/feature/explore_more_features.png") no-repeat center center;
					background-size: 352px 316px;
					position: relative;
					.explorePart{
						font-family: Poppins-Bold;
						padding: 20px;
						padding-bottom: 5px;
						width: 312px;
						height: 226px;
						font-size: 36px;
						// font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 42px;
						letter-spacing: normal;
						text-align: left;
						color: #fff;
					}

					.button{
						display: flex;
						justify-content: flex-end;
						/* align-content: flex-end; */
						align-items: flex-end;
						margin-right: 20px;
						/* margin-bottom: 20px; */
						.book_a_demo {
							/* position: absolute; */
							right: 60px;
							color: #fff;
							justify-content: space-between;
							bottom: 60px;
							width: 172px;
							box-sizing: border-box;
							height: 42px;
							padding: 10px 16px;
							line-height: 42px;
							border-radius: 5px;
							align-items: center;
							display: flex;
							/* justify-content: flex-end; */
							background-color: #062440;
						
							span {
								font-family: Poppins;
								font-size: 16px;
								// font-weight: bold;
								color: #fff;
								width: 107px;
								white-space: nowrap;
							}
						
							img {
								width: 22px;
								height: 22px;
							}
						
							&:hover {
								cursor: pointer;
							}
						}
					}


					.book_a_demo:hover {
						background-color: #041729;
					}

					.book_a_demo:active {
						background-color: #020d17;
					}
				}
			}
		}
	}
	.bottomContent{
		display:flex;
		justify-content: center;
		margin-left: 10px;
	}
</style>
